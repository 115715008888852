import React, { createContext, useState } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const LanguageContext = createContext({});

export function LanguageProvider({ children }) {
  const [idioma, setIdioma] = useState(
    cookies.get('Language') != null && cookies.get('Language') != ''
      ? cookies.get('Language')
      : 0
  );

  function changeLanguage(language) {
    const index =
      language === 'PT'
        ? (cookies.set('Language', 0), 0)
        : language === 'EN'
        ? (cookies.set('Language', 1), 1)
        : (cookies.set('Language', 2), 2);
    setIdioma(index);
  }

  return (
    <LanguageContext.Provider value={{ idioma, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
