import React from 'react';
import { LanguageProvider } from '../../context/LanguageContext';
import '../reset.css';
import { Container } from './styles';

function Layout({ children }) {
  return (
    <LanguageProvider>
      <Container>{children}</Container>
    </LanguageProvider>
  );
}

export default Layout;
